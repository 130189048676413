<template>
    <v-container>
        NotFound
    </v-container>
</template>

<script>
    
    export default {
        name: 'NotFound',
        components: {},
        data: () => ({

        })
    }
</script>
